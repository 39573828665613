import React, { useContext } from 'react'
import { Button } from '@material-ui/core';
import carregamentoCasa from '../../images/powerpacks/carregamento_casa.jpg'
import { Link } from 'gatsby';
import { AppContext } from '../../contexts/AppContext';
import Footer from '../../components/layout/Footer';
import ScrollToTop from '../../components/common/ScrollToTop';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import PowerpackTwinContainer from '../../components/layout/PowerpackTwinContainer';
import PowerPackTile from '../../components/powerpacks/PowerPackTile';
import styled from 'styled-components';
import WhatIsPowerPack from '../../components/powerpacks/WhatIsPowerPack';
import WhyPowerPack from '../../components/powerpacks/WhyPowerPack';
import PowerBoxTile from '../../components/powerpacks/PowerBoxTile';
import PowerPacksCarousel from '../../components/layout/PowerPacksCarousel';
import PowerPackConditions from '../../components/layout/PowerPackConditions';
import PowerPacksExpansionCarousel from '../../components/layout/PowerPacksExpansionCarousel';
import PowerPackExpansionConditions from '../../components/layout/PowerPackExpansionConditions';

const PowerPacksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 425px) {
        flex-direction: column;
        align-items: center;
    }
`

const ConditionsContainer = styled.div`
    padding: 60px 0 30px 0;
    background-color: var(--secondary-bg);
    display: flex;
    justify-content: center;
`

const ConditionsList = styled.div`
    display:flex;
    flex-direction: column;
    padding: 10px 30px;
` 

const ConditionItem = styled.span`
    font-size: 10pt;
    padding: 10px 0;

    &:last-child {
        padding-bottom: 0;
    }
` 

const ConditionSubtitle = styled.h4`
    margin: 20px 30px 0 30px;
    font-weight: 600;
` 

const SectionTitle = styled.h2`
    color: var(--primary-color);
    margin: 150px 10% 0 10%;
    font-size: 32pt;

    @media (max-width: 767px) {
        margin: 50px 10% 0 10%;
    }
`

export default function PowerPackLandingPage() {
    const { appState, sendEvent } = useContext(AppContext);

    return (
        <Layout>
            <div id="powerpacks"></div>
            <SEO title="PowerPacks - Carregamento em casa" />
            <div className="vbox">
                {/* <ScrollToTop /> */}
                <PowerpackTwinContainer
                    image={carregamentoCasa}
                    title={appState.i18n.powerpacks.title}
                    text={appState.i18n.powerpacks.subtitle} />
            </div>
            <SectionTitle>PowerPacks</SectionTitle>
            <PowerPacksCarousel />
            <PowerPackConditions />
            <SectionTitle>{appState.i18n.powerpacks.expansion}</SectionTitle>
            <PowerPacksExpansionCarousel />
            <PowerPackExpansionConditions />
            <div>
                <WhatIsPowerPack title={appState.i18n.powerpacks.whatIsPowerPack_title} text={appState.i18n.powerpacks.whatIsPowerPack_text} list={""} />
                {/* <WhyPowerPack /> */}
            </div>


            {/* <ConditionsContainer>
                <div>
                    <div>
                        <span className="subtitle reg-plans-signing-conditions-title">{appState.i18n["registration"]["plan_sign_conditions_title"]}</span>
                    </div>
                    <ConditionSubtitle>{appState.i18n["powerboxes"]["providerContract"]}</ConditionSubtitle>
                    <ConditionsList>
                        <ConditionItem>{appState.i18n["powerboxes"]["condition1"]}</ConditionItem>
                        <ConditionItem>{appState.i18n["powerboxes"]["condition2"]}</ConditionItem>
                        <ConditionItem>{appState.i18n["powerboxes"]["condition3"]}</ConditionItem>
                    </ConditionsList>

                    <ConditionSubtitle>{appState.i18n["powerboxes"]["equipment"]}</ConditionSubtitle>
                    <ConditionsList>
                        <ConditionItem>{appState.i18n["powerboxes"]["condition4"]}</ConditionItem>
                        <ConditionItem>{appState.i18n["powerboxes"]["condition5"]}</ConditionItem>
                        <ConditionItem>{appState.i18n["powerboxes"]["condition6"]}</ConditionItem>
                    </ConditionsList>
                </div>
            </ConditionsContainer> */}
            <Footer />
        </Layout>
    )
}
