import React from 'react'
import styled from 'styled-components'

const Article = styled.div`
        max-width: 500px;
        min-width: 250px;
        margin: 100px auto;
        padding: 0 10%
    `

const Title = styled.div`
        font-size: var(--medium-title-fontsize);
        color: var(--primary-color);
        margin-bottom: 30px;
    `

const Text = styled.div`
        line-height: 20pt;
    `

export default function WhatIsPowerPack({title, text}) {

    

    return (
        <Article>
          <Title>{title}</Title>  
          <Text>{text}</Text>
        </Article>
    )
}
